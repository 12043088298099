<template>
    <div class="profile-edit-view ViewWrapper">
        <clv-head-meta :title="$t('Edit Profile')"></clv-head-meta>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <common-box-logo></common-box-logo>

                    <!-- BODY -->
                    <div class="gk-card gk-card--core-style-1 mb-5">

                        <!-- LOADER 2020-02-04 -->
                        <element-loading :active="loading" :background-color="'rgba(255, 255, 255, 0.9)'"></element-loading>

                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Edit Profile') }}</p>
                        </div>

                        <!-- UPDATE ERROR -->
                        <div class="alert alert-danger C-Alert" role="alert" v-if="updateError">
                            <p class="mb-0">{{ $t('Profile Update Failed' ) }}</p>
                            <p class="mb-0 small">{{ $t('Please try again later') }}</p>
                        </div>

                        <!-- THE FORM -->
                        <form class="cltlr-form-label-style-1" v-on:submit.prevent="submit" data-vv-scope="profileEditForm">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="firstName">{{ $t('First Name') }}</label>
                                    <input type="text" class="form-control" name="vv_firstName" id="firstName" autofocus v-validate="'required|min:3|max:50'" v-model="formFields.firstName">
                                    <span class="small text-danger">{{ errors.first('profileEditForm.vv_firstName') }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="lastName">{{ $t('Last Name') }}</label>
                                    <input type="text" class="form-control" name="vv_lastName" id="lastName" v-validate="'required|min:3|max:50'" v-model="formFields.lastName">
                                    <span class="small text-danger">{{ errors.first('profileEditForm.vv_lastName') }}</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ $t('Language') }}</label>
                                <select class="form-control" name="vv_language" v-model="formFields.langKey">
                                    <option value="en" selected>English</option>
                                    <option value="el">Greek (Ελληνικά)</option>
                                </select>
                            </div>
                            <div class="form-group mt-4">
                                <button type="button" class="btn btn-primary" @click="submit" :disabled="!formChanged">{{ $t('Save') }}</button>
                            </div>
                        </form>

                        <common-box-links></common-box-links>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { AccountService } from '../common/services/api.service';
import AccountAccessSharedMixin from './Mixin/AccountAccessSharedMixin';

export default {
    name: 'ProfileEditView',
    mixins: [
        AccountAccessSharedMixin
    ],
    data () {
        return {
            // Persistence / Async //////////
            loading: false,
            updateError: null,

            // Form //////////
            formChanged: false,
            formFields: {
                firstName: '',
                lastName: '',
                langKey: 'en',
                email: '',
                phoneNumber: ''
            }
        };
    },
    beforeMount () {
        // If is not authenticated, go to 'login'.
        this.goToRouteBasedOnAuth(false, 'login');

        // Check auth.
        this.checkAuthRemoteAsync();

        // Map accountUser to formFields.
        this.formFields.firstName = this.accountUser.firstName;
        this.formFields.lastName = this.accountUser.lastName;
        this.formFields.langKey = this.accountUser.langKey.toLowerCase();

        // Listen to form changes.
        // If form is changed, enable submit button.
        // Else, keep it disabled.
        this.$watch('formFields', {
            handler: _.debounce(function (value) {
                if (this.formFields.firstName === this.accountUser.firstName &&
                    this.formFields.lastName === this.accountUser.lastName &&
                    this.formFields.langKey === this.accountUser.langKey) {
                    this.formChanged = false;
                } else {
                    this.formChanged = true;
                }
            }, 300),
            deep: true
        });
    },
    methods: {
        submit () {
            // Validate Form.
            this.$validator.validateAll('profileEditForm').then(valid => {
                // If form is valid, proceed.
                if (valid) {
                    // Start loader.
                    this.loading = true;

                    // Reset error.
                    this.updateError = null;

                    // Prepare DTO.
                    const dto = _.cloneDeep(this.accountUser);
                    dto.firstName = this.formFields.firstName;
                    dto.lastName = this.formFields.lastName;
                    dto.langKey = this.formFields.langKey;

                    // Make request to Zeus to sign up new account.
                    AccountService.saveAccount(dto).then(({ data }) => {
                        this.toastDefault(this.$t('Profile Saved'), false);

                        // Redirect to Profile.
                        // This redirect is necessary in order to reload the store and give the backend some time.
                        // It known it's funny but it is what it is.
                        setTimeout(function () {
                            window.location.replace('/profile');
                        }, 1500);
                    }).catch((reason) => {
                        // Set error.
                        this.updateError = reason;

                        // Stop Loader.
                        this.loading = false;
                    }).finally(() => {
                        // Stop Loader.
                        // this.loading = false; // We do not want to display the form after a successful request.
                    });
                }
            });
        }
    }
};
</script>
